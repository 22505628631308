// extracted by mini-css-extract-plugin
export var topContentContainer = "mobile-pricing-module--topContentContainer--Ye+8A";
export var topTitle = "mobile-pricing-module--topTitle--QA37J";
export var topDescTitle = "mobile-pricing-module--topDescTitle--D7CVf";
export var topDescDesc = "mobile-pricing-module--topDescDesc--Km61k";
export var priceTableContentContainer = "mobile-pricing-module--priceTableContentContainer--Fpidg";
export var planContainer = "mobile-pricing-module--planContainer--1JLAw";
export var freePlanContainer = "mobile-pricing-module--freePlanContainer--p1s84 mobile-pricing-module--planContainer--1JLAw";
export var planTitle = "mobile-pricing-module--planTitle--G2jpj";
export var planPrice = "mobile-pricing-module--planPrice--0RFBu";
export var bizPlanContainer = "mobile-pricing-module--bizPlanContainer--m4bwH mobile-pricing-module--planContainer--1JLAw";
export var startButton = "mobile-pricing-module--startButton--QTW7D";
export var basicLimitContainer = "mobile-pricing-module--basicLimitContainer--7xVgS";
export var extensionsContainer = "mobile-pricing-module--extensionsContainer--Ji1W8";
export var basicLimit = "mobile-pricing-module--basicLimit--NnoOc";
export var divider = "mobile-pricing-module--divider--ENB6K";
export var supportFeatures = "mobile-pricing-module--supportFeatures--YTfxW";
export var supportFeature = "mobile-pricing-module--supportFeature--q+RJk";
export var fontMedium = "mobile-pricing-module--fontMedium--rJm5r";
export var planLimitExtensionDesc = "mobile-pricing-module--planLimitExtensionDesc--d4Gl0";
export var switchContainer = "mobile-pricing-module--switchContainer--aAU4d";
export var billingCycleButton = "mobile-pricing-module--billingCycleButton--wDrR-";
export var active = "mobile-pricing-module--active--lI0Xb";
export var labelYearly = "mobile-pricing-module--labelYearly--O1ilM";
export var yearlyPlanSaveLabel = "mobile-pricing-module--yearlyPlanSaveLabel--S7BHb";
export var planPriceUnit = "mobile-pricing-module--planPriceUnit--z-q2r";
export var extensionsTitle = "mobile-pricing-module--extensionsTitle--6nkSe";
export var extension = "mobile-pricing-module--extension--ubAHx";
export var faqContainer = "mobile-pricing-module--faqContainer--Z0Wun";
export var faqContentContainer = "mobile-pricing-module--faqContentContainer--rlIKf";
export var faqTitle = "mobile-pricing-module--faqTitle--fECQv";
export var buttonShowMore = "mobile-pricing-module--buttonShowMore--5nEBX";
export var faqItemTitle = "mobile-pricing-module--faqItemTitle--0l2Ay";
export var faqItemBody = "mobile-pricing-module--faqItemBody--6H0ft";
export var directContactContainer = "mobile-pricing-module--directContactContainer--ubRw8";
export var directContactTitle = "mobile-pricing-module--directContactTitle--QvcV-";
export var directContactDesc = "mobile-pricing-module--directContactDesc--pxjV1";
export var directContactButton = "mobile-pricing-module--directContactButton--Kd0-s";