// extracted by mini-css-extract-plugin
export var topContentContainer = "desktop-pricing-module--topContentContainer--9Kwd6";
export var topTitle = "desktop-pricing-module--topTitle--ROSdX";
export var topDescContainer = "desktop-pricing-module--topDescContainer--RPngN";
export var planDescription = "desktop-pricing-module--planDescription--w1SEO";
export var plainLink = "desktop-pricing-module--plainLink--+vTWf";
export var priceTable = "desktop-pricing-module--priceTable--oQnii";
export var bizCell = "desktop-pricing-module--bizCell--ViD10";
export var freeCell = "desktop-pricing-module--freeCell--5321b";
export var planTitleRow = "desktop-pricing-module--planTitleRow--zJZcm";
export var priceRow = "desktop-pricing-module--priceRow---49RD";
export var priceUnitRow = "desktop-pricing-module--priceUnitRow--1JHCm";
export var startButtonRow = "desktop-pricing-module--startButtonRow--jmzZD";
export var startButton = "desktop-pricing-module--startButton--AoVUQ";
export var voltIcon = "desktop-pricing-module--voltIcon--IJRsF";
export var limitRow = "desktop-pricing-module--limitRow--9scx+";
export var extensionRow = "desktop-pricing-module--extensionRow--KyBr1";
export var checkIcon = "desktop-pricing-module--checkIcon--rALZi";
export var dividerRow = "desktop-pricing-module--dividerRow--oGqF7";
export var divider = "desktop-pricing-module--divider--plH46";
export var featureLimitRow = "desktop-pricing-module--featureLimitRow--BSQQ-";
export var faqContainer = "desktop-pricing-module--faqContainer--2c7n8";
export var faqContentContainer = "desktop-pricing-module--faqContentContainer--MtmlQ";
export var faqTitle = "desktop-pricing-module--faqTitle--cBURU";
export var buttonShowMore = "desktop-pricing-module--buttonShowMore--kK07O";
export var directContactContainer = "desktop-pricing-module--directContactContainer--ZjtGh";
export var directContactTitle = "desktop-pricing-module--directContactTitle--t1HN5";
export var directContactDesc = "desktop-pricing-module--directContactDesc--FJwpz";
export var directContactButton = "desktop-pricing-module--directContactButton--F6t2B";